<template>
  <div class="container">
    <transition name="animate">
      <div :key="key" >
        <commitment class="overlap" v-if="filterData.commitment" v-show="key == 'commitment'"  :entries="filterData.commitment" />
        <cancellation class="overlap" v-if="filterData.cancellation" v-show="key == 'cancellation'" :entries="filterData.cancellation" />
        <open class="overlap" v-if="filterData.open" v-show="key == 'open'" :entries="filterData.open" />
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  props: { entries: Array },
  data () {
    return {
      filterData: false,
      keyInt: 0,
      key: false
    }
  },
  components: {
    commitment () { return import('@/components/statusRuler/commitment') },
    cancellation () { return import('@/components/statusRuler/cancellation') },
    open () { return import('@/components/statusRuler/open') }
  },
  methods: {
    getFilterData () {
      var r = {}
      Object.keys(this.entries).forEach(function (id) {
        if (typeof r[this.entries[id].status] === 'undefined') {
          r[this.entries[id].status] = []
        }
        r[this.entries[id].status].push(this.entries[id])
      }.bind(this))
      return r
    },
    getAnimateKeys () {
      setTimeout(function () {
        this.keyInt = this.keyInt + 1

        if ((this.keyInt + 1) > Object.keys(this.filterData).length) {
          this.keyInt = 0
        }

        this.key = this.getKey()

        this.getAnimateKeys()
      }.bind(this), 4000)
    },
    getKey () {
      return Object.keys(this.filterData)[this.keyInt]
    }
  },
  mounted () {
    this.filterData = this.getFilterData()
    this.key = this.getKey()

    if (Object.keys(this.filterData).length) {
      this.getAnimateKeys()
    }
  }
}
</script>
<style lang='sass' scoped>

.container
  position: relative
  overflow: hidden
  height: 80px

.overlap
  position: absolute
  top: 0
  left: 0
  width: 100%

.animate-enter, .animate-leave
  opacity: 0

.animate-enter-active
  animation: animateMe 0.5s ease-out

.animate-leave-active
  animation: animateMe 0.5s reverse ease-out

@keyframes animateMe
  from
    opacity: 1
    transform: scaleY(0)

  to
    opacity: 1
    transform: scaleY(1)

</style>
